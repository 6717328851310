<template>
	<!-- Tickets Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Запросы</h5>
				</a-col>
				<!-- 				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-radio-group v-model="authorsHeaderBtns" size="small">
						<a-radio-button value="all">ВСЕ</a-radio-button>
						<a-radio-button value="online">НЕ ОТВЕЧЕННЫЕ</a-radio-button>
					</a-radio-group>
				</a-col> -->
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="true">

			<template slot="author" slot-scope="author">
				<div class="table-avatar-info">
					<a-avatar shape="circle" size="small" :src="author.avatar" />
					<div class="avatar-info">
						<h6>{{ author.name }}</h6>
						<p>{{ groups.get(author.group) }}</p>
					</div>
				</div>
			</template>

			<template slot="description" slot-scope="description">
				<div class="author-info">
					<p class="m-0 font-regular text-muted">{{ description.desc.substring(0, 80) + '...' }}</p>
				</div>
			</template>

			<template slot="status" slot-scope="status">
				<a-tag class="tag-status" :class="status_style[parseInt(status.status_)]">
					{{ status_txt[parseInt(status.status_)] }}
				</a-tag>
			</template>

			<template slot="created_at" slot-scope="created_at">
				<p>
					{{ new Date(created_at.date_c).toLocaleDateString() }}
				</p>
			</template>

			<template slot="editBtn" slot-scope="row">
				<a-button type="link" @click='$router.push("Ticket?tid=" + row.key)' :data-id="row.key" class="btn-edit"
					style="border: 1px solid #1e2f45;">
					Подробнее
				</a-button>
			</template>

		</a-table>
	</a-card>
	<!-- / Authors Table Card -->
</template>

<script>

export default ({
	props: {
		dataFiltered: {
			type: Array,
			default: () => [],
		},
		data: {
			type: Array,
			default: () => [],
		},
		columns: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			// Active button for the "Authors" table's card header radio button group.
			authorsHeaderBtns: 'all',
			status_txt: new Array('Не отвечено', 'Отвечено'),
			status_style: new Array(
				'ant-tag-muted',
				'ant-tag-primary',
			),
			groups: new Map([
				[1, 'Сис. аналитик'],
				[2, 'Таможня'],
				[3, 'Большие данные'],
				[4, 'Машинное обучение'],
				[5, 'Сис. аналитик (гостеприимство)'],
				[6, 'Не обучается на ЦК'],
			]),
		}
	},
})

</script>
