<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Authors Table -->
		<a-row type="flex">

			<!-- Authors Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Loading spinner -->
				<a-spin v-if="!tableLoaded" class="progress-container" size="large" />
				<!-- Authors Table Card -->
				<CardAuthorTable v-else :data="ticketsTableData" :columns="ticketsTableColumns"></CardAuthorTable>
				<!-- / Authors Table Card -->

			</a-col>
		</a-row>
	</div>
</template>

<script lang="js">
import { Spin } from 'ant-design-vue';
import axios from 'axios';

// "Authors" table component.
import CardAuthorTable from '../components/Cards/CardAuthorTable';

// "Projects" table component.
import CardProjectTable2 from '../components/Cards/CardProjectTable2';

// "Authors" table list of columns and their properties.
const ticketsTableColumns = [
	{
		title: 'АВТОР',
		dataIndex: 'author',
		scopedSlots: { customRender: 'author' },
		width: 220,
	},
	{
		title: 'ОПИСАНИЕ',
		dataIndex: 'description',
		scopedSlots: { customRender: 'description' },
	},
	{
		title: 'СТАТУС',
		dataIndex: 'status',
		scopedSlots: { customRender: 'status' },
	},
	{
		title: 'ДАТА',
		dataIndex: 'created_at',
		scopedSlots: { customRender: 'created_at' },
		class: 'text-muted',
	},
	{
		title: 'ДЕЙСТВИЕ',
		scopedSlots: { customRender: 'editBtn' },
		width: 50,
	},
];

// "Authors" table list of rows and their properties.
let ticketsTableData = [];

// "Projects" table list of columns and their properties.
const table2Columns = [
	{
		title: 'COMPANIES',
		dataIndex: 'company',
		scopedSlots: { customRender: 'company' },
		width: 300,
	},
	{
		title: 'BUDGET',
		dataIndex: 'budget',
		class: 'font-semibold text-muted',
	},
	{
		title: 'STATUS',
		dataIndex: 'status',
		class: 'font-semibold text-muted text-sm',
	},
	{
		title: 'COMPLETION',
		scopedSlots: { customRender: 'completion' },
		dataIndex: 'completion',
	},
	{
		title: '',
		scopedSlots: { customRender: 'editBtn' },
		width: 50,
	},
];

// "Projects" table list of rows and their properties.
const table2Data = [
	{
		key: '1',
		company: {
			name: 'Spotify Version',
			logo: 'images/logos/logo-spotify.svg',
		},
		status: "working",
		budget: '$14,000',
		completion: 60,
	},
	{
		key: '2',
		company: {
			name: 'Progress Track',
			logo: 'images/logos/logo-atlassian.svg',
		},
		status: "working",
		budget: '$3,000',
		completion: 10,
	},
	{
		key: '3',
		company: {
			name: 'Jira Platform Errors',
			logo: 'images/logos/logo-slack.svg',
		},
		status: "done",
		budget: 'Not Set',
		completion: {
			status: 'success',
			value: 100,
		},
	},
	{
		key: '4',
		company: {
			name: 'Launch new Mobile App',
			logo: 'images/logos/logo-spotify.svg',
		},
		status: "canceled",
		budget: '$20,600',
		completion: {
			status: 'exception',
			value: 50,
		},
	},
	{
		key: '5',
		company: {
			name: 'Web Dev',
			logo: 'images/logos/logo-webdev.svg',
		},
		status: "working",
		budget: '$4,000',
		completion: 80,
	},
	{
		key: '6',
		company: {
			name: 'Redesign Online Store',
			logo: 'images/logos/logo-invision.svg',
		},
		status: "canceled",
		budget: '$2,000',
		completion: {
			status: 'exception',
			value: 0,
		},
	},
];

export default ({
	components: {
		'a-spin': Spin,
		CardAuthorTable,
		CardProjectTable2,
	},
	methods: {
		fetchData() {
			axios
				.get("/api/v1/tickets")
				.then((response) => {
					if (!response.data || response.data.length == 0) {
						this.tableLoaded = true;
					}
					// create a temporary array to hold responce data
					let tempTicketsTableData = new Array();
					let tempTicketsFilteredTableData = new Array();

					response.data.forEach(element => {
						if (element.Tickets.length > 0) {
							const desc_ = element.Tickets.length === 1 ? element.Tickets[0].Description : `${element.Tickets.length} вопроса(ов)`;
							let status_ = 0;
							let condition = x => x.Status === 0;
							if (element.Tickets.some(condition)) {
								status_ = 0;
							}
							else {
								status_ = 1;
							}
							tempTicketsTableData.push(
								{
									key: element.ID,
									// author
									author: {
										avatar: "images/img-user.png",
										name: element.User.FullName,
										group: element.User.GroupIdx,
									},
									// description
									description: {
										desc: desc_,
									},
									// status
									status: {
										//status_: element.Tickets.Status,
										status_: status_
									},
									// date
									created_at: {
										date_c: element.CreatedAt,
										date_u: element.UpdatedAt,
									},
								}
							);
						}

						// this does a shallow copy so not big performance hit i hope
						this.ticketsTableData = tempTicketsTableData;
						this.tableLoaded = true;
					});
				})
				.catch((error) => {
					console.log(error.message);
					this.tableLoaded = true;
				});
		},
	},
	created() {
		this.fetchData();
	},
	data() {
		return {
			tableLoaded: false, // is loaded flag
			operationProgress: 0, // for progress bar
			// Associating "Authors" table data with its corresponding property.
			ticketsTableData: ticketsTableData,

			// Associating "Authors" table columns with its corresponding property.
			ticketsTableColumns: ticketsTableColumns,

			// Associating "Projects" table data with its corresponding property.
			table2Data: table2Data,

			// Associating "Projects" table columns with its corresponding property.
			table2Columns: table2Columns,
		}
	},
})

</script>

<style lang="scss">
.progress-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin-top: 10%;
}
</style>
